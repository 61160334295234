// @ts-ignore
import * as Vue from 'vue';
var allVue = Vue;
var gh = allVue.h;
var ref = allVue.ref;
import { classNames, guid, Keys, getTabIndex, templateRendering, getListeners, validatePackage, canUseDOM, kendoThemeMaps } from '@progress/kendo-vue-common';
import { FloatingActionButtonItem } from './FloatingActionButtonItem';
import { packageMetadata } from '../package-metadata';
import { position, getAnchorAlign, getPopupAlign, getTextDirectionClass } from './utils';
import { Popup } from '@progress/kendo-vue-popup';
/**
 * @hidden
 */

var FloatingActionButtonVue2 = {
  name: 'KendoVueFloatingActionButton',
  props: {
    id: String,
    dir: String,
    tabIndex: Number,
    accessKey: String,
    disabled: Boolean,
    icon: String,
    iconClass: String,
    items: [Object, Array],
    item: [String, Function, Object],
    text: String,
    alignOffset: Object,
    opened: {
      type: Boolean,
      default: undefined
    },
    align: {
      type: Object,
      default: function _default() {
        return {
          vertical: 'bottom',
          horizontal: 'end'
        };
      }
    },
    positionMode: {
      type: String,
      default: function _default() {
        return 'fixed';
      }
    },
    popupSettings: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    shape: {
      type: String,
      default: function _default() {
        return 'rectangle';
      }
    },
    rounded: {
      type: String,
      default: 'full'
    },
    fillMode: {
      type: String,
      default: 'solid',
      validator: function validator(value) {
        return [null, 'flat', 'link', 'outline', 'solid'].includes(value);
      }
    },
    size: {
      type: String,
      default: function _default() {
        return 'medium';
      }
    },
    themeColor: {
      type: String,
      default: function _default() {
        return 'primary';
      }
    }
  },
  // @ts-ignore
  emits: {
    'click': null,
    'mousedown': null,
    'mouseup': null,
    'open': null,
    'close': null,
    'itemclick': null,
    'focus': null,
    'blur': null,
    'keydown': null
  },
  data: function data() {
    return {
      currentOpened: false,
      currentFocused: false,
      focusedIndex: -1,
      currentDir: 'ltr',
      isRtl: false
    };
  },
  created: function created() {
    validatePackage(packageMetadata);
    this.element = undefined;
    this._anchor = guid();
    this.listId = guid();
    this.buttonId = guid();
  },
  mounted: function mounted() {
    this.element = this.v3 ? this.kendoAnchorRef : this.$refs[this._anchor];
    this.list = this.v3 ? this.listRef : this.$refs.list;
    this.popup = this.v3 ? this.popupRef : this.$refs.popup;
    this.currentDir = this.$props.dir !== undefined ? this.$props.dir : this.$el && getComputedStyle(this.$el).direction === 'rtl' || false;
    this.isRtl = this.currentDir === 'rtl';

    if (this.opened !== undefined) {
      position(this.$el, this.$props.align, this.$props.alignOffset, this.isRtl);
    }
  },
  updated: function updated() {
    position(this.$el, this.$props.align, this.$props.alignOffset, this.isRtl);

    if (this.currentFocused && this.element) {
      this.element.focus();
    }
  },
  computed: {
    buttonClassNames: function buttonClassNames() {
      var _a;

      var _b = this.$props,
          size = _b.size,
          icon = _b.icon,
          shape = _b.shape,
          themeColor = _b.themeColor,
          fillMode = _b.fillMode,
          rounded = _b.rounded;
      return _a = {
        'k-fab': true
      }, _a["k-fab-" + (shape || 'rectangle')] = shape !== null, _a["k-fab-" + (kendoThemeMaps.sizeMap[size] || size)] = size, _a["k-rounded-" + (kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded, _a["k-fab-" + fillMode] = fillMode, _a["k-fab-" + fillMode + "-" + themeColor] = fillMode && themeColor, _a['k-disabled'] = this.$props.disabled, _a['k-focus'] = this.currentFocused, _a["k-" + this.$props.align.vertical + "-" + this.$props.align.horizontal] = true, _a;
    },
    computedOpened: function computedOpened() {
      return this.$props.opened === undefined ? this.currentOpened : this.$props.opened;
    },
    rootClassNames: function rootClassNames() {
      return classNames({
        'k-pos-absolute': this.$props.positionMode === 'absolute',
        'k-pos-fixed': this.$props.positionMode === 'fixed'
      });
    }
  },
  methods: {
    dispatchPopupEvent: function dispatchPopupEvent(dispatchedEvent, isOpen) {
      if (!this.$props.items) {
        return;
      }

      this.$emit(isOpen ? 'open' : 'close', {
        event: dispatchedEvent,
        isOpened: !isOpen
      });
    },
    handleClick: function handleClick(event) {
      if (!event.target || this.$props.disabled) {
        return;
      }

      if (!this.$props.items) {
        this.$emit('click', event, undefined);
      } else {
        var currentOpenToggled = !this.computedOpened;
        this.currentOpened = currentOpenToggled;
        this.currentFocused = true;
        this.focusedIndex = currentOpenToggled ? 0 : -1;
        this.dispatchPopupEvent(event, !this.computedOpened);
      }
    },
    handleFocus: function handleFocus(event) {
      this.currentFocused = true;
      this.focusedIndex = this.computedOpened ? 0 : -1;
      this.$emit('focus', event, undefined);
    },
    handleBlur: function handleBlur(event) {
      this.currentFocused = false;
      this.currentOpened = false;
      this.focusedIndex = -1;
      this.$emit('blur', event, undefined);
      var fireCloseEvent = this.computedOpened;

      if (fireCloseEvent) {
        this.dispatchPopupEvent(event, false);
      }
    },
    handleMouseDown: function handleMouseDown(event) {
      event.preventDefault();
      this.$emit('mousedown', event);
    },
    handleMouseUp: function handleMouseUp(event) {
      this.$emit('mouseup', event);
    },
    dispatchItemClickEvent: function dispatchItemClickEvent(dispatchedEvent, index) {
      if (!this.$props.items) {
        return;
      }

      if (!this.$props.items[index].disabled) {
        this.$emit('itemclick', dispatchedEvent, {
          itemProps: this.$props.items[index],
          itemIndex: index
        });
      }
    },
    handleItemClick: function handleItemClick(event, clickedItemIndex) {
      if (!event.target || !this.$props.items) {
        return;
      }

      this.focusedIndex = clickedItemIndex;
      this.currentOpened = false;
      this.dispatchItemClickEvent(event, clickedItemIndex);
      this.dispatchPopupEvent(event, false);
    },
    handleItemDown: function handleItemDown(event) {
      if (canUseDOM && document.activeElement === this.element) {
        event.preventDefault();
      }
    },
    handleKeyDown: function handleKeyDown(event) {
      var currIndex = this.focusedIndex;
      var maxNavIndex = this.$props.items ? this.$props.items.length - 1 : -1;
      var isAtBottom = this.$props.align.vertical === 'bottom';

      switch (event.keyCode) {
        case Keys.enter:
        case Keys.space:
          if (currIndex >= 0) {
            this.dispatchItemClickEvent(event, currIndex);
          }

          event.preventDefault();
          this.currentOpened = !this.currentOpened;
          this.focusedIndex = !this.currentOpened ? 0 : -1;
          break;

        case Keys.esc:
          event.preventDefault();
          this.currentOpened = false;
          this.focusedIndex = -1;
          break;

        case Keys.home:
          event.preventDefault();
          this.focusedIndex = 0;
          break;

        case Keys.end:
          event.preventDefault();
          this.focusedIndex = maxNavIndex;
          break;

        case Keys.down:
        case Keys.right:
          event.preventDefault();

          if (currIndex < maxNavIndex && !isAtBottom) {
            this.focusedIndex = currIndex + 1;
          }

          if (currIndex > 0 && isAtBottom) {
            this.focusedIndex = currIndex - 1;
          }

          break;

        case Keys.up:
        case Keys.left:
          event.preventDefault();

          if (currIndex > 0 && !isAtBottom) {
            this.focusedIndex = currIndex - 1;
          }

          if (currIndex < maxNavIndex && isAtBottom) {
            this.focusedIndex = currIndex + 1;
          }

          break;

        default:
          break;
      }

      this.$emit('keydown', event, undefined);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var chipRef = ref(null);
    var kendoAnchorRef = ref(null);
    return {
      v3: v3,
      chipRef: chipRef,
      kendoAnchorRef: kendoAnchorRef
    };
  },
  render: function render(createElement) {
    var _this2 = this;

    var _this = this;

    var h = gh || createElement;
    var _a = this.$props,
        align = _a.align,
        disabled = _a.disabled,
        icon = _a.icon,
        iconClass = _a.iconClass,
        id = _a.id,
        items = _a.items,
        text = _a.text,
        tabIndex = _a.tabIndex,
        accessKey = _a.accessKey,
        popupSettings = _a.popupSettings;
    var item = templateRendering.call(this, this.$props.item, getListeners.call(this));

    var fabItems = function fabItems() {
      return items && items.map(function (element, index) {
        return (// @ts-ignore  function children
          h(FloatingActionButtonItem, {
            key: index,
            index: index,
            attrs: this.v3 ? undefined : {
              index: index,
              id: this.listId + "-" + index,
              disabled: disabled || element.disabled,
              focused: this.focusedIndex === index,
              dataItem: element,
              item: item
            },
            id: this.listId + "-" + index,
            disabled: disabled || element.disabled,
            focused: this.focusedIndex === index,
            dataItem: element,
            item: item,
            "class": classNames(element.className, getTextDirectionClass(this.currentDir || 'ltr', align.horizontal)),
            onClick: this.handleItemClick,
            on: this.v3 ? undefined : {
              "click": this.handleItemClick,
              "down": this.handleItemDown
            },
            onDown: this.handleItemDown
          })
        );
      }, this);
    };

    var isIconFab = icon && !text;
    var fabWidth = this.element ? this.element.offsetWidth : 0;
    var iconWidth = 32;
    var spacing = fabWidth / 2 - iconWidth / 2;
    return h("div", {
      "class": this.rootClassNames
    }, [h("button", {
      ref: this.v3 ? function (el) {
        _this.kendoAnchorRef = el;
      } : this._anchor,
      id: id || this.buttonId,
      attrs: this.v3 ? undefined : {
        id: id || this.buttonId,
        role: items ? 'menubutton' : 'button',
        type: 'button',
        "aria-disabled": disabled,
        "aria-expanded": items ? this.computedOpened : undefined,
        "aria-haspopup": items ? true : false,
        "aria-label": (text || '') + " floatingactionbutton",
        "aria-owns": items ? this.listId : undefined,
        "aria-activedescendant": this.focusedIndex >= 0 && items ? this.listId + "-" + this.focusedIndex : undefined,
        tabIndex: getTabIndex(tabIndex, disabled),
        accessKey: accessKey,
        dir: this.currentDir,
        disabled: disabled
      },
      role: items ? 'menubutton' : 'button',
      type: 'button',
      "aria-disabled": disabled,
      "aria-expanded": items ? this.computedOpened : undefined,
      "aria-haspopup": items ? true : false,
      "aria-label": (text || '') + " floatingactionbutton",
      "aria-owns": items ? this.listId : undefined,
      "aria-activedescendant": this.focusedIndex >= 0 && items ? this.listId + "-" + this.focusedIndex : undefined,
      tabIndex: getTabIndex(tabIndex, disabled),
      accessKey: accessKey,
      dir: this.currentDir,
      disabled: disabled,
      "class": this.buttonClassNames,
      onClick: this.handleClick,
      on: this.v3 ? undefined : {
        "click": this.handleClick,
        "mousedown": this.handleMouseDown,
        "mouseup": this.handleMouseUp,
        "focusin": this.handleFocus,
        "blur": this.handleBlur,
        "keydown": this.handleKeyDown
      },
      onMousedown: this.handleMouseDown,
      onMouseup: this.handleMouseUp,
      onFocusin: this.handleFocus,
      onBlur: this.handleBlur,
      onKeydown: this.handleKeyDown
    }, [icon ? h("span", {
      role: "presentation",
      attrs: this.v3 ? undefined : {
        role: "presentation"
      },
      "class": classNames("k-fab-icon k-icon k-i-" + icon)
    }) : iconClass ? h("span", {
      role: "presentation",
      attrs: this.v3 ? undefined : {
        role: "presentation"
      },
      "class": iconClass
    }) : null, text && h("span", {
      "class": "k-fab-text"
    }, [text])]), // @ts-ignore function children
    h(Popup, {
      ref: this.v3 ? function (el) {
        _this.popupRef = el;
      } : 'popup',
      show: this.computedOpened,
      attrs: this.v3 ? undefined : {
        show: this.computedOpened,
        anchor: this._anchor,
        animate: popupSettings.animate,
        popupClass: classNames('k-popup-transparent k-fab-popup', popupSettings.popupClass),
        anchorAlign: popupSettings.anchorAlign || getAnchorAlign(align, this.isRtl),
        popupAlign: popupSettings.popupAlign || getPopupAlign(align, this.isRtl)
      },
      anchor: this._anchor,
      animate: popupSettings.animate,
      popupClass: classNames('k-popup-transparent k-fab-popup', popupSettings.popupClass),
      anchorAlign: popupSettings.anchorAlign || getAnchorAlign(align, this.isRtl),
      popupAlign: popupSettings.popupAlign || getPopupAlign(align, this.isRtl),
      style: {
        boxShadow: 'none'
      }
    }, this.v3 ? function () {
      return [h("ul", {
        ref: _this2.v3 ? function (el) {
          _this.listRef = el;
        } : 'list',
        role: 'menu',
        attrs: _this2.v3 ? undefined : {
          role: 'menu',
          "aria-labelledby": id,
          id: _this2.listId
        },
        "aria-labelledby": id,
        id: _this2.listId,
        "class": classNames('k-fab-items', {
          'k-fab-items-bottom': align.vertical !== 'bottom',
          'k-fab-items-top': align.vertical === 'bottom'
        }),
        style: {
          paddingLeft: isIconFab ? spacing + 'px' : undefined,
          paddingRight: isIconFab ? spacing + 'px' : undefined
        }
      }, [fabItems.call(_this2)])];
    } : [h("ul", {
      ref: _this2.v3 ? function (el) {
        _this.listRef = el;
      } : 'list',
      role: 'menu',
      attrs: _this2.v3 ? undefined : {
        role: 'menu',
        "aria-labelledby": id,
        id: _this2.listId
      },
      "aria-labelledby": id,
      id: _this2.listId,
      "class": classNames('k-fab-items', {
        'k-fab-items-bottom': align.vertical !== 'bottom',
        'k-fab-items-top': align.vertical === 'bottom'
      }),
      style: {
        paddingLeft: isIconFab ? spacing + 'px' : undefined,
        paddingRight: isIconFab ? spacing + 'px' : undefined
      }
    }, [fabItems.call(_this2)])])]);
  }
};
/**
 * @hidden
 */

var FloatingActionButton = FloatingActionButtonVue2;
export { FloatingActionButton, FloatingActionButtonVue2 };