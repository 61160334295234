'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _kendoBaseComponentsVueWrapper = require('@progress/kendo-base-components-vue-wrapper');

var _TreeViewProps = require('./TreeViewProps');

var _TreeViewProps2 = _interopRequireDefault(_TreeViewProps);

var _Animation = require('./Animation');

var _Animation2 = _interopRequireDefault(_Animation);

var _Checkboxes = require('./Checkboxes');

var _Checkboxes2 = _interopRequireDefault(_Checkboxes);

var _Messages = require('./Messages');

var _Messages2 = _interopRequireDefault(_Messages);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    props: _TreeViewProps2.default,
    emits: {
        'kendowidgetready': null
    },
    mixins: [_kendoBaseComponentsVueWrapper.KendoBaseComponent, _kendoBaseComponentsVueWrapper.KendoBaseDatasourceComponent, _kendoBaseComponentsVueWrapper.KendoSharedMethods, _Animation2.default, _Checkboxes2.default, _Messages2.default],
    mounted: function mounted() {
        this._resolveChildren();

        kendo.jQuery(this.$el).kendoTreeView(this.widgetOptions);

        if (this.v3) {
            this.ready();
            this.$emit('kendowidgetready', this.kendoWidget());
        } else {
            this.$emit('kendowidgetready', this.kendoWidget());
        }
    },

    methods: {
        kendoWidget: function kendoWidget() {
            return kendo.jQuery(this.$el).getKendoTreeView();
        },
        _resolveChildren: function _resolveChildren() {
            var defaultSlot = this.v3 && this.$slots.default && typeof this.$slots.default === 'function' ? this.$slots.default() : this.$slots.default;
            var hasDataSource = this.v3 ? !this.$props.dataSource : this.$options.propsData && !this.$options.propsData['dataSource'];
            if (hasDataSource && defaultSlot) {
                var items = this.resolveInnerTags('kendo-treeview-item');

                if (items.length) {
                    this.widgetOptions['dataSource'] = items;
                }
            }
        }
    }
};