'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _kendoBaseComponentsVueWrapper = require('@progress/kendo-base-components-vue-wrapper');

var _DateInputProps = require('./DateInputProps');

var _DateInputProps2 = _interopRequireDefault(_DateInputProps);

var _Messages = require('./Messages');

var _Messages2 = _interopRequireDefault(_Messages);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    emits: {
        'update:modelValue': null,
        'kendowidgetready': null
    },
    props: _DateInputProps2.default,
    mixins: [_kendoBaseComponentsVueWrapper.KendoBaseComponent, _kendoBaseComponentsVueWrapper.KendoBaseInputComponent, _Messages2.default],
    mounted: function mounted() {
        var that = this;
        kendo.jQuery(that.$el).kendoDateInput(that.widgetOptions);
        var widget = that.kendoWidget();

        if (this.v3) {
            this.ready();
            this.$emit('kendowidgetready', this.kendoWidget());
        } else {
            this.$emit('kendowidgetready', this.kendoWidget());
        }

        that.kendoWidget().bind('change', function () {
            if (that.v3) {
                that.$emit('update:modelValue', widget.value());
            } else {
                that.$emit('changemodel', widget.value());
            }
        });
    },

    methods: {
        kendoWidget: function kendoWidget() {
            return kendo.jQuery(this.$el).getKendoDateInput();
        }
    }
};