'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    props: {
        messagesLoading: {
            type: String,
            default: undefined,
            kComposite: 'messages.loading'
        },
        messagesRequestFailed: {
            type: String,
            default: undefined,
            kComposite: 'messages.requestFailed'
        },
        messagesRetry: {
            type: String,
            default: undefined,
            kComposite: 'messages.retry'
        }
    }
};