'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    props: {
        monthContent: {
            type: String,
            default: undefined,
            kComposite: 'month.content'
        },
        monthWeekNumber: {
            type: String,
            default: undefined,
            kComposite: 'month.weekNumber'
        },
        monthEmpty: {
            type: String,
            default: undefined,
            kComposite: 'month.empty'
        }
    }
};