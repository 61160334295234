'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    props: {
        editableConfirmation: {
            type: [Boolean, String, Function],
            default: undefined,
            kComposite: 'editable.confirmation'
        },
        editableCancelDelete: {
            type: String,
            default: undefined,
            kComposite: 'editable.cancelDelete'
        },
        editableConfirmDelete: {
            type: String,
            default: undefined,
            kComposite: 'editable.confirmDelete'
        },
        editableCreateAt: {
            type: String,
            default: undefined,
            kComposite: 'editable.createAt'
        },
        editableDestroy: {
            type: Boolean,
            default: undefined,
            kComposite: 'editable.destroy'
        },
        editableMode: {
            type: String,
            default: undefined,
            kComposite: 'editable.mode'
        },
        editableTemplate: {
            type: [String, Function],
            default: undefined,
            kComposite: 'editable.template'
        },
        editableUpdate: {
            type: Boolean,
            default: undefined,
            kComposite: 'editable.update'
        },
        editableWindow: {
            type: Object,
            default: undefined,
            kComposite: 'editable.window'
        }
    }
};