"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    dateField: {
        type: String,
        default: undefined
    },
    navigator: {
        type: Object,
        default: undefined
    }
};